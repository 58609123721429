import { white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@ecomm/internationalize-ui';
import HeaderText from '../ProductsMenu/HeaderText';
import { fadeInAnimation } from '../ProductsMenu/NavAnimations';

type MobileLinkItemProps = {
  text?: string;
  link: string;
  testId: string;
  isActive: boolean;
  onClick?: React.MouseEventHandler;
};

const MobileLinkItem: React.FC<React.PropsWithChildren<MobileLinkItemProps>> = ({
  text,
  testId,
  link,
  isActive,
  onClick,
}) => {
  return (
    <StyledListItem data-test-id={testId}>
      <StyledLink to={link} href={link} onClick={onClick}>
        <StyledMenuItem>
          <div>
            <Text className={isActive ? 'active' : undefined}>{text}</Text>
          </div>
        </StyledMenuItem>
      </StyledLink>
    </StyledListItem>
  );
};

export default MobileLinkItem;

const StyledListItem = styled.li`
  display: flex;
  ${fadeInAnimation};
`;

const StyledLink = styled(Link)`
  &::after {
    content: none;
  }
`;

const Text = styled(HeaderText)`
  -webkit-font-smoothing: auto;
`;

const StyledMenuItem = styled.div`
  position: relative;
  color: ${white};
  width: auto;
  padding: 0 16px;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
    bottom: -8px;
    left: calc((100% - 16px) / 2);
    transform: rotate(90deg);
    opacity: 0;
  }

  &.expanded::before {
    opacity: 1;
    transition: opacity 600ms;
  }

  div {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 0.875rem;
    font-weight: 600;
  }
`;
