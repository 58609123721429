import {
  BIKES_NAV_KEY,
  TREADS_NAV_KEY,
  ROW_NAV_KEY,
  APP_NAV_KEY,
  BIKES_SECONDARY_LINK_COMPARE_KEY,
  TREADS_SECONDARY_LINK_COMPARE_KEY,
  APP_NAV_PRODUCT_CARD_KEY,
  ROW_NAV_PRODUCT_CARD_KEY,
  BIKE_PLUS_NAV_KEY,
  TREAD_PLUS_NAV_KEY,
  BIKE_PLUS_ROUTE,
  TREAD_PLUS_ROUTE,
  BIKE_NAV_KEY,
  TREAD_NAV_KEY,
  BIKE_ROUTE,
  TREAD_ROUTE,
} from '@acme-ui/nav/constants';
import type { ProductDetailsData, CtaWithUrlSetData, CtaWithUrlData } from '@ecomm/copy';
import type { ProductNav } from '@ecomm/copy/models.generated';
import useFilteredProductCards from '../hooks/useFilteredProductCards';
import useFilteredSecondaryLinks from '../hooks/useFilteredSecondaryLinks';

export type SubnavProductNav = 'nav.treadPlus' | 'nav.bikePlus';

interface useMenuItemLinkParams {
  products: ProductDetailsData[];
  navKey: ProductNav | SubnavProductNav;
  secondaryLinks: CtaWithUrlSetData;
}

interface useMenuItemLinkReturn {
  linkUrl?: string;
}

/*
 * This hook determines what link the menu item should route to onClick
 */
export const useMenuItemLink = ({
  navKey,
  products,
  secondaryLinks,
}: useMenuItemLinkParams): useMenuItemLinkReturn => {
  const filteredProductCards: ProductDetailsData[] = useFilteredProductCards(products);
  const filteredLinks = useFilteredSecondaryLinks(secondaryLinks);

  switch (navKey) {
    case BIKES_NAV_KEY: {
      const targetLink = getSecondaryLink(
        BIKES_SECONDARY_LINK_COMPARE_KEY,
        filteredLinks,
      );
      return { linkUrl: targetLink?.url };
    }
    case TREADS_NAV_KEY: {
      const targetLink = getSecondaryLink(
        TREADS_SECONDARY_LINK_COMPARE_KEY,
        filteredLinks,
      );
      return { linkUrl: targetLink?.url };
    }
    case APP_NAV_KEY: {
      const targetProductCTA = getProductPrimaryCTA(
        APP_NAV_PRODUCT_CARD_KEY,
        filteredProductCards,
      );
      return { linkUrl: targetProductCTA?.url };
    }
    case ROW_NAV_KEY: {
      const targetProductCTA = getProductPrimaryCTA(
        ROW_NAV_PRODUCT_CARD_KEY,
        filteredProductCards,
      );
      return { linkUrl: targetProductCTA?.url };
    }
    // mobile subnav
    case BIKE_PLUS_NAV_KEY: {
      return { linkUrl: BIKE_PLUS_ROUTE };
    }
    case TREAD_PLUS_NAV_KEY: {
      return { linkUrl: TREAD_PLUS_ROUTE };
    }
    case BIKE_NAV_KEY: {
      return { linkUrl: BIKE_ROUTE };
    }
    case TREAD_NAV_KEY: {
      return { linkUrl: TREAD_ROUTE };
    }
    default:
      return { linkUrl: undefined };
  }
};

const getSecondaryLink = (
  targetKey: string,
  secondaryLinks?: Required<CtaWithUrlData>[],
): Required<CtaWithUrlData> | undefined => {
  return secondaryLinks?.find(({ key: linkKey }) => linkKey === targetKey);
};

const getProductPrimaryCTA = (
  targetKey: string,
  products?: ProductDetailsData[],
): CtaWithUrlData | undefined => {
  return products?.find(({ key: productKey }) => productKey === targetKey)?.primaryCta;
};
