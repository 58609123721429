import React from 'react';
import { useTracking } from 'react-tracking';
import { useLocale } from '@peloton/next/hooks/useLocale';
import useNav from '@content/client/www/nav/useNav';
import { TrackingEvent } from '@ecomm/analytics/models';
import type { ProductNav } from '@ecomm/copy/models.generated';
import { APP_NAV_KEY } from '../constants';
import { useMenuItemLink } from '../ProductsMenu/useMenuItemLink';
import { BikePlusEntry, TreadPlusEntry } from './constants';
import MobileHighlightedLinkItem from './MobileHighlightedLinkItem';
import MobileLinkItem from './MobileLinkItem';

const UNIT_NAME = 'MobileMenuItem';
const PARENT = 'Mobile Nav';
const PARENT_TYPE = 'Mobile Nav (GUAC)';

const APP_NAV_TITLE = 'App';

const extraSubNavs = [BikePlusEntry, TreadPlusEntry];

type MobileMenuItemProps = {
  cmsId: ProductNav | string;
};

const MobileMenuItem: React.FC<React.PropsWithChildren<MobileMenuItemProps>> = ({
  cmsId,
}) => {
  const locale = useLocale();
  const { trackEvent } = useTracking();

  const { content, isLoading } = useNav(locale);
  const entry = content?.subnavs
    .concat(extraSubNavs)
    .find((subnav: any) => subnav.key === cmsId);

  const { key, title, ...nav } = entry ?? {};

  // overriding 'Apps' to 'App'
  const finalTitle = key === APP_NAV_KEY ? APP_NAV_TITLE : title;

  const { linkUrl } = useMenuItemLink({
    navKey: key,
    secondaryLinks: nav.secondaryLinks,
    products: nav.products ?? [],
  });

  if (isLoading || !entry) {
    return null;
  }

  const handleClick = () => {
    trackEvent({
      event: TrackingEvent.ClickedNavigation,
      properties: {
        category: key,
        linkName: `nav.${finalTitle}.header`,
        linkTo: linkUrl,
        unitName: UNIT_NAME,
        parent: PARENT,
        parentType: PARENT_TYPE,
      },
    });
  };

  if (cmsId === 'nav.deals') {
    return (
      <MobileHighlightedLinkItem
        text={finalTitle}
        link={nav.products[0].primaryCta.url}
        testId={key}
        isActive={false}
        onClick={handleClick}
      />
    );
  }

  return (
    <MobileLinkItem
      text={finalTitle}
      link={linkUrl ?? ''}
      testId={key}
      isActive={false}
      onClick={handleClick}
    />
  );
};

export default MobileMenuItem;
