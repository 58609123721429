import { green, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Link } from '@ecomm/internationalize-ui';
import HeaderText from '../ProductsMenu/HeaderText';
import { fadeInAnimation } from '../ProductsMenu/NavAnimations';

type MobileHighlightedLinkItemProps = {
  text?: string;
  link: string;
  testId: string;
  isActive: boolean;
  onClick?: React.MouseEventHandler;
};

const MobileHighlightedLinkItem: React.FC<
  React.PropsWithChildren<MobileHighlightedLinkItemProps>
> = ({ text, testId, link, isActive, onClick }) => {
  return (
    <StyledListItem data-test-id={testId}>
      <StyledLink to={link} href={link} onClick={onClick}>
        <StyledMenuItem>
          <Flex>
            <Text className={isActive ? 'active' : undefined}>{text}</Text>
          </Flex>
        </StyledMenuItem>
      </StyledLink>
    </StyledListItem>
  );
};

export default MobileHighlightedLinkItem;

const StyledListItem = styled.li`
  display: flex;
  ${fadeInAnimation};
`;

const StyledLink = styled(Link)`
  &::after {
    content: none;
  }
`;

const Text = styled(HeaderText)`
  -webkit-font-smoothing: auto;
`;

const StyledMenuItem = styled.div`
  position: relative;
  width: auto;
  padding: 0 16px;
  cursor: pointer;
  color: ${green[20]};

  span {
    font-size: 0.875rem;
    font-weight: 600;
  }
`;
