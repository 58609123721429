import { black, spacing, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { ErrorBoundary, useErrorReporter } from '@peloton/error-reporting';
import usePromosGroupedByProduct from '@content/client/hooks/usePromosGroupedByProduct';
import { HEADER_HEIGHT_MOBILE, zIndex } from '@ecomm/header/constants';
import MobileNavMenu from './MobileNavMenu';

export const MobileNav = () => {
  const { errorReporter } = useErrorReporter();
  const {
    data: activePromoByProduct,
    error: activePromoByProductError,
  } = usePromosGroupedByProduct();

  if (!activePromoByProduct && !activePromoByProductError) {
    return null;
  }

  return (
    <ErrorBoundary
      renderError={() => <></>}
      reportError={errorReporter.reportError}
      errorContext={{ tags: { component: 'mobileNav' } }}
    >
      <Container data-test-id="mobileHeaderTest">
        <MobileNavMenu />
      </Container>
    </ErrorBoundary>
  );
};

const Container = styled.nav`
  background-color: rgba(34, 37, 41, 0.9);
  align-items: center;
  color: ${white};
  display: flex;
  justify-content: space-between;
  height: ${HEADER_HEIGHT_MOBILE}px;
  position: sticky;
  top: 0;
  backdrop-filter: blur(12px);
  width: 100%;
  z-index: ${zIndex.HEADER};

  div.focus-lock {
    display: flex;
    height: 100%;
    align-items: center;
  }

  + * {
    margin-top: -${spacing[48]};
  }

  @media print {
    background: transparent !important;

    svg {
      fill: ${black};
    }
  }
`;
