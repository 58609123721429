//manually adding some new entries for the mobile subnav test
import { BIKE_PLUS_NAV_KEY, TREAD_PLUS_NAV_KEY } from '../constants';

export const BikePlusEntry = {
  key: BIKE_PLUS_NAV_KEY,
  title: 'Bike+',
  products: [],
};

export const TreadPlusEntry = {
  key: TREAD_PLUS_NAV_KEY,
  title: 'Tread+',
  products: [],
};

export const BikePlusRoute = {
  name: 'bike+',
  cmsId: BIKE_PLUS_NAV_KEY,
  rootUrls: [],
};

export const TreadPlusRoute = {
  name: 'tread+',
  cmsId: TREAD_PLUS_NAV_KEY,
  rootUrls: [],
};
