import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import FocusTrap from '@peloton/accessibility/FocusTrap';
import { BikeRoute, TreadRoute } from '@acme-ui/nav/hooks/useProductNavsMeta';
import { HeaderNavContext } from '@ecomm/header/HeaderNavProvider';
import { Menu } from '@ecomm/header/models';
import useProductNavsMeta from '../hooks/useProductNavsMeta';
import { BikePlusRoute, TreadPlusRoute } from './constants';
import MobileMenuItem from './MobileMenuItem';

const MobileNavMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { currentMenu } = useContext(HeaderNavContext);
  const availableProductNavs = useProductNavsMeta();

  let productNavs = availableProductNavs;

  productNavs = availableProductNavs.filter(
    nav =>
      nav.name !== Menu.Classes &&
      nav.name !== Menu.Accessories &&
      nav.name !== Menu.Apparel &&
      nav.name !== Menu.Deals &&
      nav.name !== Menu.Bikes &&
      nav.name !== Menu.Treads &&
      nav.name !== Menu.Row,
  );

  const finalProductNavs = [
    BikeRoute,
    BikePlusRoute,
    TreadRoute,
    TreadPlusRoute,
    ...productNavs,
  ];

  const wrapperRef = useRef<HTMLUListElement>(null);
  const menus = finalProductNavs.map(nav => nav.name);

  return (
    <StyledFocusTrap disabled={!menus.includes(currentMenu)}>
      <StyledMenuBar ref={wrapperRef} data-test-id={'productsMenuList'}>
        {finalProductNavs.map(({ cmsId }) => (
          <MobileMenuItem key={cmsId} cmsId={cmsId} />
        ))}
      </StyledMenuBar>
    </StyledFocusTrap>
  );
};

export default MobileNavMenu;

const StyledFocusTrap = styled(FocusTrap)`
  overflow-x: scroll;
  white-space: nowrap;
  flex: 1;
  position: relative;
`;

export const StyledMenuBar = styled.ul`
  margin: 0 auto;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
