import type { ReactChildrenOfLength } from '@pelotoncycle/design-system/types';
import type { FC } from 'react';
import React, { useState, useEffect, useRef } from 'react';
import Styled from 'styled-components';
import { zIndex } from '@ecomm/header/constants';

interface CollapsibleTopNavProps {
  children: ReactChildrenOfLength<2>;
  onContainerHeightChange?: (height: number) => void;
  offSetScrollHeight?: number;
}

/**
 * This component takes 2 children and collapses the top onScroll
 */
export const CollapsibleTopNav: FC<CollapsibleTopNavProps> = ({
  children,
  onContainerHeightChange,
  offSetScrollHeight = 0,
}) => {
  const [shouldShowTopNav, setShouldShowTopNav] = useState(true);
  const [topNavHeight, setTopNavHeight] = useState(0);
  const [shouldBeCollapsible, setShouldBeCollapsible] = useState(false);
  const [isFirstCollapse, setIsFirstCollapse] = useState(false);

  const topNavRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (topNavRef.current) {
      setTopNavHeight((topNavRef.current as any).clientHeight);
    }
    if (containerRef.current && onContainerHeightChange) {
      onContainerHeightChange((containerRef.current as any).clientHeight);
    }

    let prevScrollY = window.scrollY;
    const totalOffsetHeight = topNavHeight + offSetScrollHeight;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > totalOffsetHeight) {
        setShouldBeCollapsible(true);
      } else {
        if (currentScrollY <= offSetScrollHeight) {
          setIsFirstCollapse(false);
          setShouldBeCollapsible(false);
        }
      }

      //mouse scroll up
      if (prevScrollY > currentScrollY) {
        if (!isFirstCollapse) {
          setIsFirstCollapse(true);
        }
        setShouldShowTopNav(true);
      }

      //mouse scroll down
      if (prevScrollY < currentScrollY && currentScrollY > totalOffsetHeight) {
        setShouldShowTopNav(false);
      }

      prevScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [
    isFirstCollapse,
    offSetScrollHeight,
    onContainerHeightChange,
    shouldBeCollapsible,
    shouldShowTopNav,
    topNavHeight,
  ]);

  const transitionClassName = shouldBeCollapsible
    ? shouldShowTopNav
      ? 'slideDown'
      : 'slideUp'
    : '';

  return (
    <Container
      data-test-id="collapsibleTopNav"
      shouldBeCollapsible={shouldBeCollapsible}
      ref={containerRef}
    >
      <TransitionContainer
        isFirstCollapse={isFirstCollapse}
        topNavHeight={topNavHeight}
        className={transitionClassName}
      >
        <div
          ref={topNavRef}
          data-test-id="collapsibleTopNav-transition-top"
          className="transition__top"
        >
          {children[0]}
        </div>
        <div
          data-test-id="collapsibleTopNav-transition-bottom"
          className="transition__bottom"
        >
          {children[1]}
        </div>
      </TransitionContainer>
    </Container>
  );
};

const TransitionContainer = Styled.div<{
  topNavHeight: number;
  isFirstCollapse: boolean;
}>`
    position: relative;

    &.slideUp {
      transform: ${props => `translateY(-${props.topNavHeight}px);`}
      transition: ${props =>
        !props.isFirstCollapse ? 'transform 0s' : 'transform 0.4s'};
    }

    &.slideDown  {
      transform: translateY(0px);
      transition: transform 0.4s;
    }

    // add small overlap to fix edge flickering on transition
    .transition {
      &__bottom > nav {
        margin-top: -1px;
        margin-bottom: -1px;
        height: ${props => `${props.topNavHeight + 1}px`};
      }
      &__top > nav {
        z-index: ${zIndex.HEADER + 1};
      }
    }
      
`;

const Container = Styled.div<{ shouldBeCollapsible?: boolean }>`
  position: ${props => (props.shouldBeCollapsible ? 'sticky' : 'relative')};
  top: 0;
  z-index: ${zIndex.HEADER};
`;
