import { throttle } from 'frame-throttle';
import { useEffect, useRef, useState } from 'react';

export const useWindowSize = () => {
  const isClient = typeof window !== 'undefined';
  const lastWidth = useRef({
    width: isClient ? window.innerWidth : 0,
    height: isClient ? window.innerHeight : 0,
  });

  function getSize() {
    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const throttledHandleResize = throttle(function handleResize() {
      if (window.innerWidth !== lastWidth.current.width) {
        const size = getSize();
        lastWidth.current = size;
        setWindowSize(size);
      }
    });

    window.addEventListener('resize', throttledHandleResize);

    return () => {
      window.removeEventListener('resize', throttledHandleResize);
      throttledHandleResize.cancel();
    };
  }, []);

  return windowSize;
};
